<script setup>
import {computed, onMounted, onUnmounted, ref} from "vue";
import {useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import {MemberStore} from "@/uhas/store/member";

const router = useRouter();
const memberStore = MemberStore();

const {courseList, memberData} = storeToRefs(memberStore);

/**
 * Data
 */
const loading = ref(true);
const scrollComponent = ref(null)

/**
 * Computed
 */
const activeCourseList = computed(() => courseList.value.filter(item => item.active))

/**
 * Mounted
 */
onMounted (() => {
	memberStore.getCourseList()
		.finally(() => {
			loading.value = false;
		});
  // window.addEventListener("scroll", handleScroll)
});

onUnmounted(() => {
	// window.removeEventListener("scroll", handleScroll)
})

/**
 * Actions
 */
// const handleScroll = () => {
// 	const element = scrollComponent.value;
// 	const dif = element.getBoundingClientRect().bottom - window.innerHeight;
//   if (dif > -5 && dif < 100 && !loading.value) {
// 		loading.value = true;
//     memberStore.getCourseList()
// 			.finally(() => {
// 				loading.value = false;
// 			});
//   }
// }

const courseStatus = id => {
	if (!memberData.value || (!memberData.value.course?.expires[id])) {
		return 0;
	}

	if (new Date() > new Date(`${memberData.value.course?.expires[id]} 23:59:59`)) {
		return -1;
	}

	return 1;
}
</script>

<style lang="scss" scoped>
::v-deep {
	img {
		object-fit: cover;
	}
	.p-card-body, .p-card-content {
		padding: 0;
	}
	.right-detail {
		.p-card-title, .p-card-title + div {
			max-height: 150px;
			overflow-y: hidden;
		}
	}
}
::v-deep .p-component-overlay {
	animation: unset !important;
	background-color: rgba(255, 255, 255, 0.4);
}
</style>

<template>
	<div
		ref="scrollComponent"
		class=""
	>
		<template v-for="(act, i) in activeCourseList">
			<div
				v-if="act.active"
				:key="`course-${i}`"
				class="course-list mb-3"
			>
				<BlockUI
					:blocked="!act.active"
					:baseZIndex="1103"
					:autoZIndex="false"
				>
					<Card class="mx-auto w-full md:w-6 lg:w-5 xl:w-4">
						<template #content>
							<div
								class="grid grid-nogutter"
								styles="max-height: 450px;"
							>
								<div class="col-12">
									<Image
										:src="act.picture_url || 'https://cdn.uhas.com/avatar/no-img.png'"
										imageClass="border-round-top w-full h-full"
										imageStyle="max-height: 450px;"
									/>
								</div>
								<div class="col-12 p-3 md:p-4 right-detail">
									<div class="h-full flex flex-column">
										<div class="p-card-title">{{act.title}}</div>
										<div class="mb-4" v-html="act.editor_description" />
										<div class="mt-auto text-center">
											<a
												v-if="courseStatus(act.id) < 1"
												href="https://lin.ee/1eoK6Hd"
												target="_blank"
												class="p-button font-bold p-component p-button-rounded p-button-lg w-full"
											>
												<span class="p-button-label">รายละเอียด</span>
											</a>
											<Button
												v-else
												class="p-button-rounded p-button-lg w-full"
												label="รายละเอียด"
												@click="router.push({name: 'course-sessions', params: {id: act.id}})"
											/>
											<div class="mt-2">
												<InlineMessage :severity="courseStatus(act.id) === -1 ? 'error' : (courseStatus(act.id) === 0 ? 'warn' : 'info')">
													{{
														courseStatus(act.id) === -1 ? 'คอร์สเรียนนี้หมดอายุแล้ว' : (
															courseStatus(act.id) === 0 ? 'คุณยังไม่ได้สมัครคอร์สเรียน' : `คอร์สเรียนนี้จะหมดอายุเมื่อ ${memberData.course?.expires[act.id]}`
														)
													}}
												</InlineMessage>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
					</Card>
				</BlockUI>
			</div>
		</template>
	</div>
	<ProgressBar
		mode="indeterminate"
		style="height: 0.3em"
		class="mt-4"
		:class="{'hidden': !loading}"
	/>
</template>
